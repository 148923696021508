.header {
  width: 100vw;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  // position: sticky;
  // top: 0;
  // z-index: 9;
  // background-color: var(--background);
  .logo {
    width: 32px;
    height: 32px;
    cursor: pointer;
    border-radius: 45px;
  }

  .avatarAndTitle {
    display: flex;
    align-items: center;

    h1 {
      margin-left: 10px;
      font-size: 34px;
      font-weight: 600;
      color: #fff;
      -webkit-text-stroke: 1px #fff; /* 设置描边宽度和颜色，颜色应为背景色的对比色 */
      color: transparent; /* 文本颜色设置为透明，露出描边 */
      font-weight: bold; /* 可选，加粗字体有助于提高描边可见度 */
    }
  }
}

@media screen and (max-width: 860px) {
  .avatarAndTitle {
    h1 {
      display: none;
    }
  }
}

// @media screen and (max-width: 430px) {
//   .avatarAndTitle {
//     h1 {
//       display: none;
//     }
//   }
// }
