.footer {
  padding: 10px;
  font-size: 12px;
  text-align: center;
  color: #fff;
  flex-shrink: 0; /* 不允许footer在空间不足时收缩 */
}

@media screen and (max-width: 430px) {
  .footer {
    text-align: right;
  }
}
