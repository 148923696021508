.contact {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  display: flex;

  img {
    width: 30px;
    height: 30px;
  }
  .email {
    margin: 0 10px;
  }
}
.qrcode {
  width: 100px;
  height: 100px;
}
