.theme {
  img {
    width: 32px;
    height: 32px;
    cursor: pointer;
    border-radius: 90px;
    box-shadow: 0 0 3px #fff;
  }
}

.theme:hover {
  border-radius: 90px;
  box-shadow: 0 0 5px #fff;
}
