.layout {
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  scrollbar-width: none;
}

.contentContainer {
  flex-grow: 1; /* 让content在有可用空间时尽可能增长 */
  overflow-y: auto; /* 如果内容过多，添加滚动条 */
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  scrollbar-width: none;
}
