.avatar {
  border: 1px dashed #fffdfd;
  width: 70px;
  height: 70px;
  border-radius: 45px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: static;
  top: 10px;
  left: 10px;
  z-index: 9;
  img {
    width: 60px;
    height: 60px;
    border-radius: 45px;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  img:hover {
    transform: rotate(45deg);
  }
}
