.loadingSpinner {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .loading {
    position: relative;
    width: 48px;
    height: 48px;
    animation: satellite 3s infinite linear;
    border: 1px solid;
    border-radius: 100%;
  }

  .loading:before,
  .loading:after {
    position: absolute;
    left: 1px;
    top: 1px;
    width: 12px;
    height: 12px;
    content: "";
    border-radius: 100%;
    background-color: #fff;
    box-shadow: 0 0 10px #fff;
  }

  .loading:after {
    right: 0;
    width: 20px;
    height: 20px;
    margin: 13px;
  }

  @keyframes satellite {
    from {
      transform: rotate(0) translateZ(0);
    }

    to {
      transform: rotate(360deg) translateZ(0);
    }
  }
}
