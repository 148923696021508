.ToolNav {
  width: auto;
  display: grid;
  color: #fff;
  font-weight: bolder;
  font-size: 16px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
  height: 170px;
  max-width: 420px;
}
.ToolNavItem {
  background: rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  cursor: pointer;
  height: 100%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 10px;
  border-radius: 10px;
  img {
    width: 32px;
    height: 32px;
    margin-right: 10px;
  }
}

.ToolNavForHeader {
  height: auto;
  display: flex;
  max-width: none;
  .ToolNavItem {
    border: none;
    background: none;
    padding: 0;
  }
}

.ToolNavItem:hover {
  color: #f4efef;
  img {
    transform: scale(1.2);
  }
}
.ToolNavItemActive {
  color: #fff;
}

@media screen and (max-width: 480px) {
  .ToolNavForHeader {
    .ToolNavItem {
      font-size: 12px;
      img {
        display: none;
      }
    }
  }
}
