@tailwind base;
@tailwind components;
@tailwind utilities;

@layer {
  :root {
    --background: #fff;
    --foreground: rgb(33, 36, 40);
    --border: #e3e3e7;
    --color-text: rgb(15, 23, 42);
    --color-background-component: rgba(0, 0, 0, 0.8);
    --color-border: #10162f;
  }

  .dark {
    --background: rgb(33, 36, 40);
    --foreground: #f9f9f9;
    --border: #27272a;
    --color-text: #fff;
    --color-background-component: rgba(0, 0, 0, 0.25);
    --color-border: #fff;
  }
}
@layer base {
  * {
    @apply box-border border-[--border];
  }

  body {
    @apply bg-[--background] text-[--foreground];
  }
}

@font-face {
  font-family: "alimama";
  font-weight: 700;
  src: url("./assets/font/7EhtOs5Ucv5i.woff2") format("woff2");
  font-display: swap;
}

body {
  margin: 0;
  font-family: alimama, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: none;
  /* background-color: #1f1f1f; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
