.fallback {
  background-color: #333;
  height: 100vh;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  h1 {
    font-size: 30px;
    width: 100%;
    font-weight: 600;
  }

  .content {
    img {
      width: 300px;
    }
    span {
      cursor: pointer;
    }
    span:hover {
      color: #00ff00;
    }
  }
}
